import { defineStore } from "pinia";
import { useStorage } from "@vueuse/core";
import { CurrencySettings } from "@/types";

export const useCurrencyStore = defineStore("currency", () => {
  const getDefaultCurrency = (): CurrencySettings => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    switch (timeZone) {
      case "Africa/Lagos":
        return {
          name: "Naira",
          code: "NGN",
          symbol: "₦",
        };
      default:
        return {
          name: "United States dollar",
          code: "USD",
          symbol: "$",
        };
    }
  };

  const currencies = [
    {
      name: "Naira",
      code: "NGN",
      symbol: "₦",
    },
    {
      name: "Pound sterling",
      code: "GBP",
      symbol: "£",
    },
    {
      name: "United States dollar",
      code: "USD",
      symbol: "$",
    },
  ] as const;

  const chosenCurrency = useStorage<{ currency: CurrencySettings | false }>(
    "currency-settings",
    {
      currency: false,
    },
  );

  const defaultCurrency = computed(() => {
    if (chosenCurrency.value.currency) {
      return { currency: chosenCurrency.value.currency };
    }
    return { currency: getDefaultCurrency() };
  });

  const setDefaultCurrency = (currency: CurrencySettings) => {
    chosenCurrency.value.currency = currency;
  };

  return { currencies, defaultCurrency, setDefaultCurrency };
});
